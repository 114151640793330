import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-signup-image',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './signup-image.component.html',
  styleUrl: './signup-image.component.css'
})
export class SignupImageComponent {
}
