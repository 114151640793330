<div class="h-full w-full bg-cover bg-center bg-no-repeat gradient">

    <div class="flex flex-col  items-center justify-center h-full">
        <div class="flex-row border-solid rounded-[5px] border-[1px] border-outline-variant ">
            <div class="flex flex-col w-[360px] justify-center rounded py-8 px-4 bg-surface-container-lowest">
                <div class="flex flex-col gap-y-4 items-center">
                    <mat-icon svgIcon="ic_sbox_brackets" class="h-[50px] w-[50px]"></mat-icon>
                    <div class="flex flex-col gap-y-2 items-center text-center justify-center">
                        <p class="mat-title-large text-primary ">Reset Password</p>
                        <p class=" mat-body-large text-on-surface-variant  ">Please provide your registered email
                            address</p>
                    </div>
                </div>


                <form [formGroup]="forgotPasswordForm" (keyup.enter)="isValid()?verify():showError()" class="mt-8">
                    <mat-form-field appearance="outline" class="w-full mt-8">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Email" formControlName="email" type="email">
                        <mat-error>
                            <ng-container *ngIf="
                            forgotPasswordForm.controls['email'].errors &&
                            (forgotPasswordForm.controls['email'].dirty || forgotPasswordForm.controls['email'].touched)
                          ">
                                <ng-container
                                    *ngFor="let error of forgotPasswordForm.controls['email'].errors | keyvalue">
                                    <ng-container *ngIf="error.key">
                                        <span>{{ error.value['message'] }}</span>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>

                </form>

                <div class="justify-center mt-[240px]">
                    <ng-container *ngIf="!saveForm; else loading">
                        <button mat-flat-button class="w-full" (click)="isValid()?verify():showError()"
                            [id]="'sendResetPasswordEmailButton'">Send Reset
                            Password
                            Email</button>
                    </ng-container>
                </div>
                <ng-template #loading>
                    <button mat-flat-button class="w-full" [id]="'buttondefaultButton'">
                        <canvas riv="cta-loading" width="28" height="28">
                            <riv-player name="loading-1" play></riv-player>
                        </canvas>
                    </button>
                </ng-template>

            </div>
        </div>


        <div class="bottom-8 absolute">
            <span class="mat-label-medium text-on-surface-variant">© {{getYear()}} Sandbox</span>
        </div>
    </div>

</div>