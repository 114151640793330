<div class="flex flex-row justify-center">
  <div class="flex flex-col bg-none min-h-screen z-10 flex-grow w-1/2 relative">
    <app-signup-image />
  </div>
  <div class="flex flex-col justify-center bg-surface-container-lowest z-50 px-[32px] py-[40px] w-1/2">

    <div class="flex flex-col mt-4">
      <div class="flex flex-col gap-[16px]">

        <span class="flex items-center mat-body-medium text-primary">

          <button [routerLink]="['../']" mat-icon-button class="ml-[-12px]" [id]="'chevronLeftButton'">
            <mat-icon class="material-symbols-rounded text-primary ">chevron_left </mat-icon>
          </button>Step 2
        </span>

        <div class="flex flex-col gap-[8px]">
          <span class="mat-title-large text-on-surface">Set your account password</span>
          <span class="text-on-surface-variant mat-body-medium">Set a strong password to secure your account</span>
        </div>
      </div>
      <div class="flex flex-col mt-[48px]">
        <form [formGroup]="signUpForm" (keyup.enter)="checkFormValidation()?onSignUp():showError()"
          class="flex flex-col gap-2">
          <mat-form-field appearance="outline" class="w-[452px]">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email">
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-[452px]">
            <mat-label>Password</mat-label>

            <input matInput placeholder="Password" formControlName="password"
              [type]="showPassword ? 'text' : 'password'" autocomplete="off">

            <mat-error>
              <ng-container *ngIf="
                              signUpForm.controls['password'].errors &&
                              (signUpForm.controls['password'].dirty || signUpForm.controls['password'].touched)
                            ">
                <ng-container *ngFor="let error of signUpForm.controls['password'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span>{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>


            <mat-icon matSuffix class="material-symbols-rounded text-on-surface-variant" (click)="showPassword=false"
              *ngIf="showPassword">
              visibility_off</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-on-surface-variant" (click)="showPassword=true"
              *ngIf="!showPassword">
              visibility</mat-icon>
          </mat-form-field>


          <div class="flex flex-col gap-y-3">
            <div class="flex gap-x-2">
              <mat-icon [ngClass]="isLengthValid ? 'text-primary' : 'text-outline-variant'"
                class=" material-symbols-rounded">
                check_circle
              </mat-icon>
              <p class="mat-body-medium text-on-surface">Must be at least 8 characters long</p>
            </div>

            <div class="flex flex-row gap-2">
              <mat-icon [ngClass]="isLowercaseValid ? 'text-primary' : 'text-outline-variant'"
                class=" material-symbols-rounded">
                check_circle
              </mat-icon>
              <p class="mat-body-medium text-on-surface">Must contain at least one lowercase character</p>
            </div>

            <div class="flex flex-row gap-2">
              <mat-icon [ngClass]="isUppercaseValid ? 'text-primary' : 'text-outline-variant'"
                class=" material-symbols-rounded">
                check_circle
              </mat-icon>
              <p class="mat-body-medium text-on-surface">Must contain at least one uppercase character</p>
            </div>

            <div class="flex flex-row gap-2">
              <mat-icon [ngClass]="isNumberValid ? 'text-primary' : 'text-outline-variant'"
                class=" material-symbols-rounded">
                check_circle
              </mat-icon>
              <p class="mat-body-medium text-on-surface">Must contain at least one number</p>
            </div>

            <div class="flex flex-row gap-2">
              <mat-icon [ngClass]="isSpecialCharacterValid ? 'text-primary' : 'text-outline-variant'"
                class=" material-symbols-rounded">
                check_circle
              </mat-icon>
              <p class="mat-body-medium text-on-surface">Must include at least one special character</p>
            </div>
          </div>

          <mat-form-field appearance="outline" class="w-[452px] mt-8">
            <mat-label>Confirm Password</mat-label>
            <input matInput placeholder="Password" formControlName="confirmPassword"
              [type]="showConfirmPassword ? 'text' : 'password'" autocomplete="off">

            <mat-error>
              <ng-container *ngIf="
                              signUpForm.controls['confirmPassword'].errors &&
                              (signUpForm.controls['confirmPassword'].dirty || signUpForm.controls['confirmPassword'].touched)
                            ">
                <ng-container *ngFor="let error of signUpForm.controls['confirmPassword'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span>{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>

            <mat-icon matSuffix class="material-symbols-rounded text-on-surface-variant"
              (click)="showConfirmPassword=false" *ngIf="showConfirmPassword">
              visibility_off</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-on-surface-variant"
              (click)="showConfirmPassword=true" *ngIf="!showConfirmPassword">
              visibility</mat-icon>
          </mat-form-field>

        </form>

        <!-- get started cta -->
        <div class="flex flex-wrap justify-start mt-[24px]">
          <ng-container *ngIf="!saveForm; else loading">
            <button mat-flat-button (click)="checkFormValidation()?onSignUp():showError()" [id]="'signUpButton'">Sign
              up</button>
          </ng-container>
        </div>
        <ng-template #loading>
          <button mat-flat-button [id]="'buttondefaultButton'">
            <canvas riv="cta-loading" width="28" height="28">
              <riv-player name="loading-1" play></riv-player>
            </canvas>
          </button>
        </ng-template>
      </div>
    </div>


  </div>
</div>