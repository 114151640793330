import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxReactiveFormsModule, RxwebValidators } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { Subject, takeUntil } from 'rxjs';
import { SnackbarService } from '../../services/snackbar.service';
import { UserActions } from '../../store/user/user.actions';

@Component({
  selector: 'app-confirm-forgot-password',
  standalone: true,
  imports: [MatIconModule, RouterModule, CommonModule, MatButtonModule, MatFormFieldModule, MatTooltipModule, MatInputModule, RiveModule, ReactiveFormsModule, RxReactiveFormsModule],
  templateUrl: './confirm-forgot-password.component.html',
  styleUrl: './confirm-forgot-password.component.css'
})
export class ConfirmForgotPasswordComponent {

  code?: string;
  email?: string;
  confirmForgotPasswordForm: FormGroup;
  saveForm: boolean = false;
  destroy$ = new Subject<void>();
  showPassword: boolean = false;
  showPasswordForConfirm: boolean = false;

  constructor(
    private router: Router,
    private fb: RxFormBuilder,
    private store: Store,
    private route: ActivatedRoute,
    private actions$: Actions,
    private snackbarService: SnackbarService
  ) {
    this.confirmForgotPasswordForm = this.fb.group({
      password: [
        '',
        [
          RxwebValidators.notEmpty({ message: 'Password is required' }),
          RxwebValidators.pattern({
            expression: {
              password:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
            },
            message:
              'Password must be at least 8 characters long and include lowercase, uppercase, number, and special character.',
          }),
        ],
      ],
      confirmPassword: [
        '',
        [
          RxwebValidators.notEmpty({ message: 'Confirm Password is required' }),
          RxwebValidators.compare({
            fieldName: 'password',
            message: 'Passwords do not match',
          }),
        ],
      ],
    });

  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.email = decodeURIComponent(params['email'])
      this.code = params['code']
    });
  }
  verify() {
    this.saveForm = true
    const password = this.confirmForgotPasswordForm?.controls['password'].value
    if (this.code && this.email) {
      this.store.dispatch(UserActions.CONFIRM_FORGOT_PASSWORD({ email: this.email, code: this.code, password: password }))

      this.actions$.pipe(ofType(UserActions.CONFIRM_FORGOT_PASSWORD_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
        this.snackbarService.openSnackBar('Password reset successfully', undefined)
        this.saveForm = false
        this.router.navigate(['/login'])
      })

      this.actions$.pipe(ofType(UserActions.CONFIRM_FORGOT_PASSWORD_FAILED), takeUntil(this.destroy$)).subscribe(() => {
        this.snackbarService.openSnackBar('Password reset failed', undefined)
        this.saveForm = false

      })

    }
    else {
      if (this.code == null)
        this.snackbarService.openSnackBar('Code is required', undefined);

      else
        this.snackbarService.openSnackBar('Email is required', undefined);
    }

  }

  getYear() {
    return new Date().getFullYear();
  }

  isValid() {
    return this.confirmForgotPasswordForm.valid
  }

  showError() {
    this.confirmForgotPasswordForm.markAllAsTouched();
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
