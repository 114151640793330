import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxReactiveFormsModule, RxwebValidators } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { Subject, takeUntil } from 'rxjs';
import { SnackbarService } from '../../services/snackbar.service';
import { UserActions } from '../../store/user/user.actions';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, RiveModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, RxReactiveFormsModule, CommonModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent {

  forgotPasswordForm: FormGroup;
  saveForm: boolean = false;
  destroy$ = new Subject<void>();

  constructor(
    private fb: RxFormBuilder,
    private store: Store,
    private actions$: Actions,
    private snackbarService: SnackbarService
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [RxwebValidators.notEmpty({ message: 'Email is required' }), RxwebValidators.email({ message: 'Invalid email' })]]
    });

  }

  verify() {

    this.saveForm = true;

    const email = this.forgotPasswordForm.controls['email'].value;

    this.store.dispatch(UserActions.FORGOT_PASSWORD({ email }))

    this.actions$.pipe(ofType(UserActions.FORGOT_PASSWORD_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
      this.saveForm = false;
      this.snackbarService.openSnackBar('Reset password link sent', undefined);
    })

    this.actions$.pipe(ofType(UserActions.FORGOT_PASSWORD_FAILED), takeUntil(this.destroy$)).subscribe(() => {
      this.saveForm = false;
      this.snackbarService.openSnackBar('Failed to send reset password link', undefined);
    })
  }

  isValid() {
    return this.forgotPasswordForm.valid && this.forgotPasswordForm?.controls['email'].value
  }

  showError() {
    this.forgotPasswordForm.markAllAsTouched();
  }

  getYear() {
    return new Date().getFullYear();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
