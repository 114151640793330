<div class="flex flex-row justify-center">
  <div class="flex flex-col bg-none min-h-screen z-10 flex-grow w-1/2 relative">
    <app-signup-image />
  </div>
  <div class="flex flex-col justify-start bg-surface-container-lowest z-50 px-[32px] py-[40px] w-1/2">
    <div class="flex flex-col w-[490px] mt-auto mb-auto">
      <div class="flex flex-col gap-12">

        <div class="flex items-center justify-center w-[52px] h-[52px] rounded-full border border-primary">
          <mat-icon
            class="material-symbols-rounded flex items-center justify-center text-primary w-[28px] h-[28px]">verified_user</mat-icon>
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex justify-start">
            <span class="mat-title-large text-on-surface ">Great, now let’s verify your email</span>
          </div>
          <div class="flex justify-start">
            <span class="mat-body-medium text-on-surface-variant">We’ve send a verification link to <span
                class="mat-title-small text-on-surface">{{email}}</span> </span>
          </div>
        </div>
        <div class="flex flex-col gap-[16px]">
          <!-- <button mat-stroked-button [id]="'resendEmailButton'">
            Resend email
          </button> -->
          <div class="flex justify-start">
            <span class="mat-body-medium text-on-surface-variant">Need help? <a href="#" target="_blank"
                class="mat-title-small text-primary">Visit support</a> or <a href="#" target="_blank"
                class="mat-title-small text-primary">contact us.</a> </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>