import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CacheService } from '../../services/cache.service';
import { SignupImageComponent } from '../signup-image/signup-image.component';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css'],
  standalone: true,
  imports: [MatIconModule, SignupImageComponent, MatButtonModule]
})
export class VerificationComponent implements OnInit {
  email: string | undefined;

  constructor(
    private cacheService: CacheService,
  ) {
    this.cacheService.getFormData().subscribe((user) => {
      this.email = user.email;
    });
  }

  ngOnInit() {
    this.cacheService.clearDataStream();
  }

}
