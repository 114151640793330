import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { instanceToPlain } from "class-transformer";
import { catchError, map, switchMap } from "rxjs";
import { EntitlementsService } from "../../services/entitlements.service";
import { SnackbarService } from "../../services/snackbar.service";
import { UserActions } from "./user.actions";



@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private entitlementService: EntitlementsService,
        private store: Store,
        private snackBarService: SnackbarService
    ) { }

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.LOGIN),
            switchMap(action =>
                this.entitlementService.login(action.email, action.password).pipe(
                    map(response => UserActions.LOGIN_SUCCESS({ accessToken: response.data.access_token, refreshToken: response.data.refresh_token })),
                    catchError(async err => {
                        this.snackBarService.openSnackBar('Login failed', undefined);
                        return UserActions.LOGIN_FAILED({ err: err });
                    })
                )
            )
        )
    );

    signup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.SIGNUP),
            switchMap(action =>
                this.entitlementService.signup(instanceToPlain(action.user), action.redirectUrl).pipe(
                    map(response => UserActions.SIGNUP_SUCCESS({ user: response.data! })),
                    catchError(async err => {
                        this.snackBarService.openSnackBar('Signup failed', undefined);
                        return UserActions.SIGNUP_FAILED({ err: err });
                    })
                )
            )
        )
    );

    checkUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.CHECK_USER),
            switchMap(action =>
                this.entitlementService.checkUser(action.username).pipe(
                    map(response => UserActions.CHECK_USER_SUCCESS({ exists: response.data })),
                    catchError(async err => {
                        this.snackBarService.openSnackBar('Failed to check user', undefined);
                        return UserActions.CHECK_USER_FAILED({ err: err });
                    })
                )
            )
        )
    );


    verifySignup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.VERIFY_SIGNUP),
            switchMap(action =>
                this.entitlementService.verifySignup(action.email, action.confirmationCode).pipe(
                    map(() => UserActions.VERIFY_SIGNUP_SUCCESS()),
                    catchError(async err => {
                        this.snackBarService.openSnackBar('Failed to verify signup', undefined);
                        return UserActions.VERIFY_SIGNUP_FAILED({ err: err });
                    })
                )
            )
        )
    );

    fogotPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.FORGOT_PASSWORD),
            switchMap(action =>
                this.entitlementService.forgotPassword(action.email).pipe(
                    map(() => UserActions.FORGOT_PASSWORD_SUCCESS()),
                    catchError(async err => {
                        return UserActions.FORGOT_PASSWORD_FAILED({ err: err });
                    })
                )
            )
        )
    );

    confirmFogotPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.CONFIRM_FORGOT_PASSWORD),
            switchMap(action =>
                this.entitlementService.confirmForgotPassword(action.email, action.code, action.password).pipe(
                    map(() => UserActions.CONFIRM_FORGOT_PASSWORD_SUCCESS()),
                    catchError(async err => {
                        return UserActions.CONFIRM_FORGOT_PASSWORD_FAILED({ err: err });
                    })
                )
            )
        )
    );
}
