export const environment = {
    production: true,
    profile: 'dev',

    sandbox_logo_white: '/assets/icons/sandbox_logo_white.svg',

    // product logos
    ic_sbox_brackets: '/assets/icons/ic_sbox_brackets.svg',

    // endpoints
    auth_services_endpoint: 'https://keq7mx11sf.execute-api.ap-south-1.amazonaws.com/dev',
    authentication_host: 'https://dev1-accounts.sandbox.co.in',
    org_quicko_cms_endpoint: 'https://d2l1ae64bvxp7e.cloudfront.net',
    sandbox_console_host: 'https://dev-console.sandbox.co.in/',
    cognito_domain: 'https://sandbox.auth.ap-south-1.amazoncognito.com',
    cognito_client_id: '21m7bl6mdm2uomv041c6pem5k6',
    login_url: 'https://dev1-accounts.sandbox.co.in/login'
};
