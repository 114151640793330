import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class ThemeService {

    private theme = new BehaviorSubject<Theme>(Theme.LIGHT);

    public theme$ = this.theme.asObservable();

    private systemThemePreference: Theme = Theme.LIGHT;

    private userThemePreference: Theme;

    private renderer: Renderer2;

    constructor(
        rendererFactory: RendererFactory2,
        private cookieService: CookieService
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);

        const isDarkThemeEnabled = window.matchMedia("(prefers-color-scheme: dark)").matches;

        if (isDarkThemeEnabled == true)
            this.systemThemePreference = Theme.DARK;

        const themePreferenceFromCookie = this.cookieService.get("theme")

        if (themePreferenceFromCookie.toLowerCase() === Theme.DARK) {
            this.userThemePreference = Theme.DARK;
        }

        else this.userThemePreference = Theme.LIGHT
    }

    public initializeTheme() {
        if (this.userThemePreference != null) {
            this.setTheme(this.userThemePreference);
            this.updateThemingClasses(this.userThemePreference);
        }
        else if (this.systemThemePreference != null) {
            this.setTheme(this.systemThemePreference);
            this.updateThemingClasses(this.systemThemePreference);
        }
        else {
            this.setTheme(Theme.LIGHT);
            this.updateThemingClasses(Theme.LIGHT);
        }

    }

    public setTheme(theme: Theme) {
        this.theme.next(theme);

        this.updateThemingClasses(theme);

        if (environment.production)
            this.cookieService.set("theme", theme, undefined, '/', `.sandbox.co.in`, true);
        else
            this.cookieService.set("theme", theme, undefined, '/', `localhost`, true);

    }

    private updateThemingClasses(theme: Theme) {
        switch (theme) {
            case Theme.LIGHT:
                this.renderer.addClass(document.body, 'light-theme');
                this.renderer.removeClass(document.body, 'dark-theme');
                break;
            case Theme.DARK:
                this.renderer.addClass(document.body, 'dark-theme');
                this.renderer.removeClass(document.body, 'light-theme');
                break;
            default:
                this.renderer.addClass(document.body, 'light-theme');
                this.renderer.removeClass(document.body, 'dark-theme');
                break;
        }
    }
}

export enum Theme {
    LIGHT = "light",
    DARK = "dark"
}