import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {

    authenticated: BehaviorSubject<any>;
    jwtHelper: JwtHelperService = new JwtHelperService();

    constructor(private cookieService: CookieService) {
        this.authenticated = new BehaviorSubject(this.isAuthenticated());
    }

    setSessionToken(token: string): void {
        if (environment.production) {
            this.cookieService.set('SESSIONID', token, 1, '/', '.sandbox.co.in', true, 'Strict');
        } else {
            this.cookieService.set('SESSIONID', token, undefined, undefined, undefined, false, 'Strict');
        }
    }

    setRefereshToken(token: string): void {
        if (environment.production) {
            this.cookieService.set('REFRESHSESSIONID', token, 1, '/', '.sandbox.co.in', true, 'Strict');
        } else {
            this.cookieService.set('REFRESHSESSIONID', token, undefined, undefined, undefined, false, 'Strict');
        }
    }

    getToken(): string {
        return this.cookieService.get('SESSIONID');
    }

    isAuthenticated(): boolean {
        try {
            if (this.getToken() != null) {
                let token = this.getToken().split('.');

                if (token && _.size(token) == 3 && this.jwtHelper.decodeToken(this.getToken()) &&
                    !this.jwtHelper.isTokenExpired(this.getToken())) {
                    return true;
                }
            }
        } catch (error) {
            return false;
        }

        return false;
    }

}


