<div class="flex flex-row justify-center">
  <div class="flex flex-col bg-none min-h-screen z-10 flex-grow w-1/2 relative">
    <app-signup-image />
  </div>
  <div class="flex flex-col justify-center bg-surface-container-lowest z-50 px-[32px] py-[40px] w-1/2">


    <div class="flex flex-col gap-12 w-[468px]">
      <div class="flex flex-col gap-[8px]">
        <span class="text-on-surface mat-title-large">Start your free trial</span>
        <span class="text-on-surface-variant mat-body-medium">Power your onboarding and tax compliance flows with
          us</span>
      </div>
      <div class="flex flex-col">
        <form [formGroup]="accountDetailForm" (keyup.enter)="isValid()? onGetStartedClick():showError()"
          (ngSubmit)="isValid()? onGetStartedClick():showError()" class="flex flex-col gap-y-5">
          <mat-form-field appearance="outline" class="w-[468px]">
            <mat-label>Email</mat-label>
            <input matInput required placeholder="Business Email" formControlName="email" type="email">
            <mat-error>
              <ng-container *ngIf="
                          accountDetailForm.controls['email'].errors &&
                          (accountDetailForm.controls['email'].dirty || accountDetailForm.controls['email'].touched)
                        ">
                <ng-container *ngFor="let error of accountDetailForm.controls['email'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span>{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
          <div class="flex flex-row gap-x-4 w-[468px]">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>First name</mat-label>
              <input matInput required placeholder="First name" formControlName="firstName" type="text">
              <mat-error>
                <ng-container *ngIf="
                            accountDetailForm.controls['firstName'].errors &&
                            (accountDetailForm.controls['firstName'].dirty || accountDetailForm.controls['firstName'].touched)
                          ">
                  <ng-container *ngFor="let error of accountDetailForm.controls['firstName'].errors | keyvalue">
                    <ng-container *ngIf="error.key">
                      <span>{{ error.value['message'] }}</span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Last name</mat-label>
              <input matInput required placeholder="Last name" formControlName="lastName" type="text">
              <mat-error>
                <ng-container *ngIf="
                            accountDetailForm.controls['lastName'].errors &&
                            (accountDetailForm.controls['lastName'].dirty || accountDetailForm.controls['lastName'].touched)
                          ">
                  <ng-container *ngFor="let error of accountDetailForm.controls['lastName'].errors | keyvalue">
                    <ng-container *ngIf="error.key">
                      <span>{{ error.value['message'] }}</span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" class="w-[468px]">
            <mat-label>Company</mat-label>
            <input matInput required placeholder="Company" formControlName="business" type="text">
            <mat-error>
              <ng-container *ngIf="
                          accountDetailForm.controls['business'].errors &&
                          (accountDetailForm.controls['business'].dirty || accountDetailForm.controls['business'].touched)
                        ">
                <ng-container *ngFor="let error of accountDetailForm.controls['business'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span>{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <div class="flex justify-between w-[468px] gap-[8px]">
            <mat-form-field appearance="outline" class="w-[94px]">
              <mat-select class="flex  w-[94px]" [(value)]="selectedCountry" panelWidth="400px">
                <mat-select-trigger>
                  <img *ngIf="selectedCountry" matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 " />
                </mat-select-trigger>
                <mat-option *ngFor=" let c of countryList" [value]="c">
                  <div class=" flex">
                    <img src="{{c.flag}}" alt="flag" class="h-5 w-7 " />
                    <span>{{c.name}}</span>
                  </div>
                </mat-option>
              </mat-select>
              <mat-icon class="text-on-surface py-2 material-symbols-rounded" matSuffix>keyboard_arrow_down</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-[468px]" floatLabel="always">
              <mat-label>Phone</mat-label>
              <p matTextPrefix class="text-on-surface  mr-[12px] " *ngIf="selectedCountry">
                +{{selectedCountry.numeric_code}}</p>
              <input matInput formControlName="phone" type="text">
              <mat-error>
                <ng-container *ngIf="
                            accountDetailForm.controls['phone'].errors &&
                            (accountDetailForm.controls['phone'].dirty || accountDetailForm.controls['phone'].touched)
                          ">
                  <ng-container *ngFor="let error of accountDetailForm.controls['phone'].errors | keyvalue">
                    <ng-container *ngIf="error.key">
                      <span>{{ error.value['message'] }}</span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>
        </form>

        <div>
          <div class="flex flex-row mt-[24px]">
            <span class="mat-body-small text-on-surface-variant">By clicking "Get Started", you agree to our <a
                class="text-primary cursor-pointer" (click)="navigateToTermsOfUse()" target="_blank">Terms of Use</a>
              and <a class="text-primary cursor-pointer" (click)="navigateToTermsOfUse()" target="_blank">Privacy
                Policy</a></span>
          </div>

          <div class="flex flex-wrap justify-start mt-4">
            <ng-container *ngIf="!saveForm; else loading">
              <button mat-flat-button class="w-full" (click)="isValid()? onGetStartedClick():showError()"
                [id]="'getStartedButton'">Get
                Started</button>
            </ng-container>
          </div>
          <ng-template #loading>
            <button mat-flat-button class="w-full" [id]="'buttondefaultButton'">
              <canvas riv="cta-loading" width="28" height="28">
                <riv-player name="loading-1" play></riv-player>
              </canvas>
            </button>
          </ng-template>


          <div class="flex flex-row justify-between w-[468px]">

            <button (click)="loginWithGoogle()"
              class="hover:bg-surface-container-low  border h-12 py-3 mt-4 rounded-md w-full text-primary">
              <div class="flex flex-row gap-2 items-center justify-center">
                <img src="assets/icons/google_logo.svg">
                <span class="mat-label-large">
                  Continue with Google
                </span>
              </div>
            </button>

          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-between mt-12">
      <span class="mat-body-large text-on-surface-variant">Have an account? <span [routerLink]="['/login']"
          class="mat-title-medium text-primary cursor-pointer">Log in</span></span>
    </div>
  </div>
</div>