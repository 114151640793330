import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxReactiveFormsModule, RxwebValidators } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import queryString from 'query-string';
import { take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../bean/User';
import { CacheService } from '../../services/cache.service';
import { EntitlementsService } from '../../services/entitlements.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UserActions } from '../../store/user/user.actions';
import { SignupImageComponent } from '../signup-image/signup-image.component';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
  standalone: true,
  imports: [RiveModule, MatInputModule, MatButtonModule, SignupImageComponent, MatIconModule, MatFormFieldModule, MatDividerModule, CommonModule, RouterModule, ReactiveFormsModule, RxReactiveFormsModule]
})
export class PasswordComponent implements OnInit {

  signUpForm: FormGroup;
  saveForm: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isValidationEnabled = false;
  passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=\|"':;<,>.?\/\\])[A-Za-z\d!@#$%^&*()_\-+=\|"':;<,>.?\/\\]{8,}$/;
  userCountryCode: string = "";
  redirectUrl: any;

  isLengthValid: boolean = false;
  isLowercaseValid: boolean = false;
  isUppercaseValid: boolean = false;
  isNumberValid: boolean = false;
  isSpecialCharacterValid: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheService,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private snackBarService: SnackbarService,
    private fb: RxFormBuilder,
    private store: Store,
    private actions$: Actions
  ) {

    // Todo: Regex for email
    this.signUpForm = this.fb.group({
      email: ['', [RxwebValidators.notEmpty({ message: "Email is required" }),]],
      firstName: ['', [RxwebValidators.notEmpty({ message: "First name is required" })]],
      lastName: ['', RxwebValidators.notEmpty({ message: "Last name is required" })],
      business: ['', RxwebValidators.notEmpty({ message: "Business is required" })],
      phone: [''],
      password: ['', RxwebValidators.notEmpty({ message: "Password is required" })],
      confirmPassword: ['', RxwebValidators.compare({ fieldName: 'password', message: "Passwords must match" })],
    });
    this.signUpForm.controls['email'].disable();

  }

  ngOnInit() {
    this.cacheService.getFormData().subscribe((user) => {
      if (user) {
        this.signUpForm.patchValue(user)
        this.userCountryCode = user.countryCode!;
      }
    })

    this.activatedRoute.queryParams.subscribe((params) => {
      const inviteData = params['invite'];
      const email = params['email']

      if (inviteData) {
        const decodedData = queryString.parse(atob(inviteData));
        this.redirectUrl = decodedData['redirect']
        this.signUpForm.controls['email'].setValue(decodedData['email']);
      }

      else if (email) {
        this.signUpForm.controls['email'].setValue(email);

      }
    });

    this.signUpForm.controls['password'].valueChanges
      .subscribe((newPassword: string) => {
        if (newPassword) {
          this.isLengthValid = newPassword.length >= 8;
          this.isLowercaseValid = /[a-z]/.test(newPassword);
          this.isUppercaseValid = /[A-Z]/.test(newPassword);
          this.isNumberValid = /\d/.test(newPassword);
          this.isSpecialCharacterValid = /[!@#$%^&*()_\-+=\|"':;<,>.?\/\\]/.test(newPassword);
        }
      });

  }

  customRequiredValidator(control: AbstractControl): ValidatorFn {
    return () => {
      if (!this.isValidationEnabled) {
        return null;
      }
      return control.value.trim() === '' ? { required: true } : null
    };
  }

  checkFormValidation() {
    return this.signUpForm.valid && this.isNumberValid && this.isLengthValid && this.isLowercaseValid
      && this.isUppercaseValid && this.isSpecialCharacterValid;
  }

  showError() {
    this.signUpForm.markAllAsTouched();
  }

  onSignUp() {
    this.saveForm = true;
    let phone;
    if (this.signUpForm.controls['phone'].value) {
      phone = "+" + this.userCountryCode + this.signUpForm.controls['phone'].value.replace(/\s/g, '')
    }

    const user = new User();
    user.email = this.signUpForm.controls['email'].value;
    user.firstName = this.signUpForm.controls['firstName'].value;
    user.lastName = this.signUpForm.controls['lastName'].value;
    user.business = this.signUpForm.controls['business'].value;
    user.phone = phone;
    user.countryCode = this.userCountryCode.toString();
    user.password = this.signUpForm.controls['password'].value;


    if (!this.redirectUrl) {
      this.redirectUrl = environment.sandbox_console_host
    }
    this.store.dispatch(UserActions.SIGNUP({
      user: (user),
      redirectUrl: this.redirectUrl
    }))

    this.actions$.pipe(ofType(UserActions.SIGNUP_SUCCESS), take(1)).subscribe((data) => {
      this.router.navigate(['signup', 'verification'], {
        queryParamsHandling: 'merge'
      })
      this.saveForm = false;
    })

    this.actions$.pipe(ofType(UserActions.SIGNUP_FAILED), take(1)).subscribe(({ err }) => {
      if (err.error.message) {
        this.snackBarService.openSnackBar('Failed to signup', undefined);
        this.saveForm = false;
      }
    })

  }

}
