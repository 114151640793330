<section class="pl-[180px] gradient h-screen">
    <div class="flex flex-col pr-20 pt-12 pb-14 gap-y-[124px] ">

        <mat-icon class="w-[127px] h-[20px]" svgIcon="sandbox_logo_white"></mat-icon>

        <div class="flex flex-col gap-y-12">

            <div class="flex flex-col gap-y-5 ">

                <mat-icon
                    class="w-[127px] h-[20px] material-symbols-rounded text-on-success-container">rocket_launch</mat-icon>

                <div class="flex flex-col gap-y-2 ">
                    <span class=" mb-0 mat-headline-medium text-on-success-container">Seamless onboarding</span>
                    <span class=" mat-body-medium w-[440px] text-on-success-container">Get started in minutes with
                        instant API keys and flexible payment options.</span>
                </div>
            </div>

            <div class="flex flex-col gap-y-5">

                <mat-icon class="w-[127px] h-[20px] material-symbols-rounded text-on-success-container">bolt</mat-icon>

                <div class="flex flex-col gap-y-2 ">
                    <span class=" mb-0 mat-headline-medium text-on-success-container">Instant access</span>
                    <span class=" mat-body-medium w-[440px] text-on-success-container">Use postman collection, SDKs to
                        go
                        from zero to live in minutes.</span>
                </div>
            </div>


            <div class="flex flex-col gap-y-5">

                <mat-icon
                    class="w-[127px] h-[20px] material-symbols-rounded text-on-success-container">bar_chart</mat-icon>

                <div class="flex flex-col gap-y-2 ">
                    <span class=" mb-0 mat-headline-medium text-on-success-container">Realtime Insights</span>
                    <span class=" mat-body-medium w-[440px] text-on-success-container">View transaction logs, monitor
                        usage
                        and generate on-demand reports.</span>
                </div>
            </div>

        </div>
    </div>
</section>