<div class="h-full w-full bg-cover bg-center bg-no-repeat gradient">

    <div class="flex flex-col  items-center justify-center h-full">
        <div class="flex-row border-solid rounded-[5px] border-[1px] border-outline-variant">
            <div class="flex flex-col w-[360px] justify-center rounded py-8 px-4 bg-surface-container-lowest">
                <div class="flex flex-col gap-y-4 items-center">
                    <mat-icon svgIcon="ic_sbox_brackets" class="h-[50px] w-[50px]"></mat-icon>
                    <div class="flex flex-col gap-y-2 items-center justify-center">
                        <p class="mat-title-large text-primary ">Login to Sandbox</p>
                        <p class=" mat-body-large text-on-surface-variant  ">Welcome back!</p>
                    </div>
                </div>

                <form [formGroup]="loginForm" (keyup.enter)="isValid()?verify():showError()" class="mt-8">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Email" formControlName="email" type="email">
                        <mat-error>
                            <ng-container *ngIf="
                            loginForm.controls['email'].errors &&
                            (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)
                          ">
                                <ng-container *ngFor="let error of loginForm.controls['email'].errors | keyvalue">
                                    <ng-container *ngIf="error.key">
                                        <span>{{ error.value['message'] }}</span>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                    <!-- password form field -->
                    <mat-form-field appearance="outline" class="w-full mt-2">
                        <mat-label>Password</mat-label>
                        <input matInput formControlName="password" [type]="showPassword ? 'text' : 'password'"
                            autocomplete="off">
                        <mat-error>
                            <ng-container *ngIf="
                              loginForm.controls['password'].errors &&
                              (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)
                            ">
                                <ng-container *ngFor="let error of loginForm.controls['password'].errors | keyvalue">
                                    <ng-container *ngIf="error.key">
                                        <span>{{ error.value['message'] }}</span>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </mat-error>
                        <mat-icon matSuffix matTooltip="{{ showPassword ? 'Hide password' : 'Show password' }}"
                            matTooltipPosition="above"
                            class="material-symbols-rounded text-on-surface-variant cursor-pointer"
                            (click)="showPassword = !showPassword">
                            {{ showPassword ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                    </mat-form-field>
                </form>

                <!-- forgot pasword -->
                <div class="items-start">
                    <button (click)="navigateToForgotPassword()" class="mat-label-medium text-primary">Forgot
                        Password?</button>
                </div>

                <!-- login cta -->
                <div class="justify-center mt-8">
                    <ng-container *ngIf="!saveForm; else loading">
                        <button mat-flat-button class="w-full" (click)="isValid()?verify():showError()"
                            [id]="'loginButton'">Login</button>
                    </ng-container>
                </div>
                <ng-template #loading>
                    <button mat-flat-button class="w-full" [id]="'buttondefaultButton'">
                        <canvas riv="cta-loading" width="28" height="28">
                            <riv-player name="loading-1" play></riv-player>
                        </canvas>
                    </button>
                </ng-template>

                <!-- divider -->
                <div class="flex flex-row items-center justify-center mt-4">
                    <div class="flex-1">
                        <mat-divider class="text-outline-variant"></mat-divider>
                    </div>
                    <div class="mat-body-small text-on-surface-variant px-2">or</div>
                    <div class="flex-1">
                        <mat-divider class="text-outline-variant"></mat-divider>
                    </div>
                </div>


                <button (click)="loginWithGoogle()"
                    class="hover:bg-surface-container-low border h-12 py-3 mt-4 rounded-md w-full text-primary">
                    <div class="flex flex-row gap-2 items-center justify-center">
                        <img src="assets/icons/google_logo.svg">
                        <span class="mat-label-large">
                            Continue with Google
                        </span>
                    </div>
                </button>

                <div class="flex items-center mt-8  justify-center">
                    <span class="mat-body-large text-on-surface-variant">New to Sandbox?</span>&nbsp;<span
                        [routerLink]="['/signup']" class="mat-title-medium text-primary cursor-pointer">Get
                        Started</span>
                </div>

            </div>
        </div>


        <div class="bottom-8 absolute">
            <span class="mat-label-medium text-on-surface-variant">© {{getYear()}} Sandbox</span>
        </div>
    </div>

</div>