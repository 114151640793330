<div class="h-full w-full bg-cover bg-center bg-no-repeat gradient">

    <div class="flex flex-col  items-center justify-center h-full">
        <div class="flex-row border-solid rounded-[5px] border-[1px] border-outline-variant">
            <div class="flex flex-col w-[360px] justify-center rounded py-8 px-4 bg-surface-container-lowest">
                <div class="flex flex-col gap-y-4 items-center">
                    <mat-icon svgIcon="ic_sbox_brackets" class="h-[50px] w-[50px]"></mat-icon>
                    <div class="flex flex-col gap-y-2 items-center justify-center">
                        <p class="mat-title-large text-primary ">Reset password</p>
                        <p class=" mat-body-large text-on-surface-variant text-center">Keep your account secure with a
                            unique
                            password</p>
                    </div>
                </div>

                <form [formGroup]="confirmForgotPasswordForm" (keyup.enter)="isValid()?verify():showError()"
                    class="mt-8">
                    <mat-form-field appearance="outline" class="w-full mt-2">
                        <mat-label>Password</mat-label>
                        <input matInput formControlName="password" [type]="showPassword ? 'text' : 'password'"
                            autocomplete="off">
                        <mat-error>
                            <ng-container *ngIf="
                              confirmForgotPasswordForm.controls['password'].errors &&
                              (confirmForgotPasswordForm.controls['password'].dirty || confirmForgotPasswordForm.controls['password'].touched)
                            ">
                                <ng-container
                                    *ngFor="let error of confirmForgotPasswordForm.controls['password'].errors | keyvalue">
                                    <ng-container *ngIf="error.key">
                                        <span>{{ error.value['message'] }}</span>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </mat-error>
                        <mat-icon matSuffix matTooltip="{{ showPassword ? 'Hide password' : 'Show password' }}"
                            matTooltipPosition="above"
                            class="material-symbols-rounded text-on-surface-variant cursor-pointer"
                            (click)="showPassword = !showPassword">
                            {{ showPassword ? 'visibility_off' : 'visibility' }}
                        </mat-icon>

                        <mat-hint class="text-on-surface-variant mat-label-medium w-full  block">Must be at least 8
                            characters long and include at least one lowercase, one uppercase, one number, and one
                            special character.</mat-hint>
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="w-full mt-[94px]">
                        <mat-label>Password</mat-label>
                        <input matInput formControlName="confirmPassword"
                            [type]="showPasswordForConfirm ? 'text' : 'password'" autocomplete="off">
                        <mat-error>
                            <ng-container *ngIf="
                              confirmForgotPasswordForm.controls['confirmPassword'].errors &&
                              (confirmForgotPasswordForm.controls['confirmPassword'].dirty || confirmForgotPasswordForm.controls['confirmPassword'].touched)
                            ">
                                <ng-container
                                    *ngFor="let error of confirmForgotPasswordForm.controls['confirmPassword'].errors | keyvalue">
                                    <ng-container *ngIf="error.key">
                                        <span>{{ error.value['message'] }}</span>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </mat-error>
                        <mat-icon matSuffix
                            matTooltip="{{ showPasswordForConfirm ? 'Hide password' : 'Show password' }}"
                            matTooltipPosition="above"
                            class="material-symbols-rounded text-on-surface-variant cursor-pointer"
                            (click)="showPasswordForConfirm = !showPasswordForConfirm">
                            {{ showPasswordForConfirm ? 'visibility_off' : 'visibility' }}
                        </mat-icon>


                    </mat-form-field>
                </form>


                <!-- login cta -->
                <div class="justify-center  mt-[138px]">
                    <ng-container *ngIf="!saveForm; else loading">
                        <button mat-flat-button class="w-full" (click)="isValid()?verify():showError()"
                            [id]="'savePasswordButton'">Save
                            Password</button>
                    </ng-container>
                </div>
                <ng-template #loading>
                    <button mat-flat-button class="w-full" [id]="'buttondefaultButton'">
                        <canvas riv="cta-loading" width="28" height="28">
                            <riv-player name="loading-1" play></riv-player>
                        </canvas>
                    </button>
                </ng-template>

            </div>
        </div>


        <div class="bottom-8 absolute">
            <span class="mat-label-medium text-on-surface-variant">© {{getYear()}} Sandbox</span>
        </div>
    </div>

</div>