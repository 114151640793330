import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../bean/ApiResponse';
import { User } from '../bean/User';

@Injectable({
    providedIn: 'root',
})
export class EntitlementsService {
    private endpoint;
    constructor(
        private httpClient: HttpClient,
    ) {
        this.endpoint = environment.auth_services_endpoint + '/auth';
    }


    login(email: string, password: string) {
        var user = {
            '@entity': 'in.co.sandbox.user',
            email: email,
            password: password,
        };
        const url = this.endpoint + '/login';
        return this.httpClient.post<ApiResponse<any>>(url, user);
    }

    signup(user: Record<string, any>, redirectUrl: string) {
        const url = this.endpoint + '/signup';

        const params = new HttpParams().set('redirect', redirectUrl);
        return this.httpClient.post<ApiResponse<User>>(url, user, { params });
    }

    checkUser(username: string): Observable<ApiResponse<any>> {
        const url = this.endpoint + '/user/check';
        const authorizeParams = new URLSearchParams();
        authorizeParams.set('username', username);
        return this.httpClient.get<ApiResponse<any>>(`${url}?${authorizeParams.toString()}`);
    }

    changePassword(email: string, tempPassword: string, newPassword: string) {
        const url = this.endpoint + '/change-password';
        const userDetails = {
            email: email,
            temporary_password: tempPassword,
            permanent_password: newPassword
        }

        return this.httpClient.patch(url, userDetails);
    }

    updateUser(user: User, token: string) {
        let headers = new HttpHeaders().set('Authorization', token);
        const url = this.endpoint + '/user';
        return this.httpClient.patch(url, user, { headers: headers });
    }

    verifySignup(email: any, confirmationCode: any) {
        const url = this.endpoint + '/signup/verify';
        let params = new HttpParams();

        if (email) {
            params = params.set('email', email);
        }

        if (confirmationCode) {
            params = params.set('code', confirmationCode);
        }

        return this.httpClient.post(url, null, { params: params });
    }

    forgotPassword(email: string) {
        const url = this.endpoint + '/forgot-password';
        return this.httpClient.post(url, { email });
    }


    confirmForgotPassword(email: string, code: string, password: string) {
        const url = this.endpoint + '/forgot-password/confirm';
        return this.httpClient.post(url, { email: email, confirmation_code: code, password: password });
    }

};