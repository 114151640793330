import { createAction, props } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { User } from '../../bean/User';

export const UserActions = {
    LOGIN: createAction('[USER] LOGIN ' + uuidv4(), props<{ email: string; password: string }>()),
    LOGIN_SUCCESS: createAction('[USER] LOGIN Success ' + uuidv4(), props<{ accessToken: string, refreshToken: string }>()),
    LOGIN_FAILED: createAction('[USER] LOGIN Failed ' + uuidv4(), props<{ err: any }>()),

    SIGNUP: createAction('[USER] SIGNUP ' + uuidv4(), props<{ user: User; redirectUrl: string }>()),
    SIGNUP_SUCCESS: createAction('[USER] SIGNUP Success ' + uuidv4(), props<{ user: User }>()),
    SIGNUP_FAILED: createAction('[USER] SIGNUP Failed ' + uuidv4(), props<{ err: any }>()),

    CHECK_USER: createAction('[USER] CHECK User ' + uuidv4(), props<{ username: string }>()),
    CHECK_USER_SUCCESS: createAction('[USER] CHECK User Success ' + uuidv4(), props<{ exists: boolean }>()),
    CHECK_USER_FAILED: createAction('[USER] CHECK User Failed ' + uuidv4(), props<{ err: string }>()),

    FORGOT_PASSWORD: createAction('[USER] FORGOT Password ' + uuidv4(), props<{ email: string }>()),
    FORGOT_PASSWORD_SUCCESS: createAction('[USER] FORGOT Password Success ' + uuidv4()),
    FORGOT_PASSWORD_FAILED: createAction('[USER] FORGOT Password Failed ' + uuidv4(), props<{ err: any }>()),

    CONFIRM_FORGOT_PASSWORD: createAction('[USER] CONFIRM FORGOT Password ' + uuidv4(), props<{ email: string, code: string, password: string }>()),
    CONFIRM_FORGOT_PASSWORD_SUCCESS: createAction('[USER] CONFIRM FORGOT Password Success ' + uuidv4()),
    CONFIRM_FORGOT_PASSWORD_FAILED: createAction('[USER] CONFIRM FORGOT Password Failed ' + uuidv4(), props<{ err: any }>()),

    VERIFY_SIGNUP: createAction('[USER] VERIFY Signup ' + uuidv4(), props<{ email: string; confirmationCode: string }>()),
    VERIFY_SIGNUP_SUCCESS: createAction('[USER] VERIFY Signup Success ' + uuidv4()),
    VERIFY_SIGNUP_FAILED: createAction('[USER] VERIFY Signup Failed ' + uuidv4(), props<{ err: any }>()),
};
